/** @jsx jsx */
import { jsx } from "theme-ui";
import { useEffect } from "react";
import { navigate } from "gatsby";

const Unsubscription = () => {
  useEffect(() => {
    navigate("/unsubscribe");
  }, []);
  return <div />;
};

export default Unsubscription;
